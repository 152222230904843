import React, { useRef, useState } from "react";
import "./App.css";
import clown_audio from "./clown_audio.mp3";
import clown_img from "./clown_img.png";
import playButton from "./play_button.svg";
import pauseButton from "./pause_button.svg";

function App() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <div className="container">
      <img src={clown_img} className="clown" alt="clown_img"></img>
      <audio autoPlay loop ref={audioRef}>
        <source src={clown_audio} type="audio/mp3" />
      </audio>

      <button onClick={togglePlay}>
        {isPlaying ? <img src={pauseButton} /> : <img src={playButton} />}
      </button>
    </div>
  );
}

export default App;
